<template>
  <div class="home">
   <HelloWorld />
   <FallasComunes />
    <Servicios />
    <Videos />
    <Relacionados />
    <Shop />
    </div>
</template>
<script>
// @ is an alias to /src
import HelloWorld from '../components/HelloWorld.vue'
import Shop from '../components/Shop.vue'
import Relacionados from '../components/Relacionados.vue'
import Videos from '../components/Videos.vue'
import Servicios from '../components/Servicios.vue'
import FallasComunes from '../components/FallasComunes.vue'

export default {
  name: 'ReparacionPortatiles',
  components: {
    HelloWorld,
    Shop,
    Relacionados,
    Videos,
    Servicios,
    FallasComunes
  }
}
</script>
