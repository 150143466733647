<template>
<div class="home">
<div class="row">
<div class="col-md-4" v-for="( fallo , item ) of fallas" :key="item" >
<article class=" agencia border border-primary ">
<h2 class=" h2 text-xs-center text-md-center" v-text="fallo.title"></h2>
<h3  class="  pb-2 pt-5 text-uppercase" v-text="fallo.type"></h3>
<img class="wow zoomIn pb-3  pb-2 pt-5" data-wow-duration=".3s" data-wow-delay=".3s" :src="fallo.imagen.img" :alt="fallo.imagen.alt">
<ul v-for="(causa, item) of fallo.causas" :key="item" class="list-group">
<li class="text-left list-group-item list-group-item-primary ">{{causa}}</li>
</ul>
<p class="text-justify pb-2 pt-5" v-html="fallo.texto"></p>
</article>
</div>
</div>
</div>
</template>
<script>
export default {
  name: 'FallasComunes',
  props: {
    msg: String
  },
  data () {
    return {
      fallas: [
        {
          title: 'Fallas Comunes',
          type: 'hardware',
          imagen: { img: 'https://www.cmlabtec.com/images/solucion-problemas-computadores-portatiles.png', alt: 'servicio tecnico especializado en Reparacion de computadores  Bogota' },
          causas: { uno: 'Derramó líquidos en su equipo portátil.', dos: 'Golpes y caídas de su laptop portátil.', tres: 'Dejó caer su computador portátil y no inicia.', cuatro: 'Ruidos extraños en su máquina al iniciar', cinco: 'Cambio de pantallas y táctiles.', seis: 'Reemplazo de batería, teclado y demás repuestos del equipo.', siete: 'Algunas teclas de mi portatil se dañaron', ocho: 'La bateria  no dura  cargada .' },
          texto: 'Proveemos soporte técnico a todos sus equipos tanto a empresas y hogares en Bogotá con servicio en sitio.'
        },
        {
          title: 'Fallas Comunes',
          type: 'software',
          imagen: { img: 'https://www.cmlabtec.com/images/actualizacion-programas-computador.png', alt: 'servicio tecnico especializado en software  a domicilio' },
          causas: { uno: 'Lentitud al inicio de Windows.', dos: 'Pantallazo azul mientras trabaja o carga cualquier aplicación.', tres: 'No arranca el sistema operativo Windows.', cuatro: 'No navega en Internet', cinco: 'El equipo se reinicia constantemente.', seis: 'El portátil se apaga después de un tiempo repetidamente.', siete: 'Perdí u olvidé la contraseña de inicio de mi computador portátil.', ocho: 'Cambiar la versión del sistema operativo Windows 10 7 8 8.1 .' },
          texto: 'Proveemos soporte técnico a todos sus equipos tanto a empresas y hogares en Bogotá con servicio en sitio.'
        },
        {
          title: 'Fallas Comunes',
          type: 'virus',
          imagen: { img: 'https://www.cmlabtec.com/images/solucion-problemas-con-virus.png', alt: 'servicio tecnico especializado en  computadores portátiles en Bogotá' },
          causas: { uno: 'Virus y/o publicidad no deseada mientras trabaja o navega en Internet', dos: 'Se venció la licencia de mi programa Antivirus', tres: 'Mis memorias USB están infectadas por un virus.', cuatro: 'Perdida de información cuando su sistema no arranca correctamente.', cinco: 'Necesito recuperar mis archivos en mi equipo que no prende.', seis: 'Sacar la informacion de mi portatil a otro computador' },
          texto: 'Servició técnico especializado a domicilio con personal motorizados para un servicio tecnico más rápido.Soporte técnico outsourcing para empresas EMPRESAS PYMES'
        }
      ]
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.agencia {
      color: #101010;
    margin-top: 5px;
    background-color:  #12AFAF;
    padding: 1rem;
    border: 1px solid gainsboro;
    border-radius: 1rem;
    box-shadow: 3px -7px 9px -1px black;
    }
</style>
